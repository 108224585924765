import React from 'react';
import { GatsbySeo } from 'gatsby-plugin-next-seo/lib';
import { useIntl } from 'gatsby-plugin-intl';

import { Segment } from '@components/segment/Segment';

import SendingSuccess from '@components/form-response/sending-success/SendingSuccess';


const SendingSuccessTemplate = () => {
  const intl = useIntl();

  return (
    <>
      <GatsbySeo
        noindex={true}
        nofollow={true}
        title={`Avionaut - ${intl.formatMessage({ id: 'metaTitle__registrationIncomplete' })}`}
      />
      <Segment>
        <SendingSuccess />
      </Segment>
    </>
  );
};

export default SendingSuccessTemplate;
